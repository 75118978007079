.queue-group-item {
    background: #657a85;
    width: 100%;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.queue-times-table {
    margin: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
   
}

a {

    color: unset !important
}


.selected-item {

    background-color: #81b2ca;
}

.queue-group-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    height: 35px;
    align-items: flex-start;
    justify-content: space-around;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 20px;
}

.queue-group-item:first-child {

border-top-left-radius: 20px;
border-bottom-left-radius: 20px;

}

.queue-group-item:last-child {

    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    
    }

a.active {
    width: 20%;
    height: 100%;
    cursor: pointer;
}


.queue-times-table-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    background: #4e5d66;
    color: white;
}

.queue-times-table-item:nth-child(even) {
    background: #28373f

}

.queue-times-table-item:first-child {
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;

}

.queue-times-table-item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
 
 }


.queue-times-table-item-inner {
    width: 100%;
    display: flex;
    align-items: center;
}

.queue-times-table-item-title {
    flex-grow: 1;
    text-align: left;
    padding-left: 15px
}

.queue-times-table-item-queue-time {
    width: 30%;
    margin-right: 20px;
    text-align: right;
    font-size: 16px;
}

span.queue-time-minutes {
    color: white;
    font-size: 15px;
}

.queue-times-table-item-queue-time-time {
    font-size: 20px;
}
.queue-times-table-item-warning {
    width: 10%;
    color: orangered;
}

.queue-times-table-item-queue-time-closedwithdowntime {
    color: gray;
    font-size: 16px;
}

.queue-times-item-table-text {
    flex-grow: 1;
}

.queue-times-table-item-subtext {
    text-align: left;
    font-size: 13px;
    padding-left: 15px;
}

.queue-item-warning-title {
    height: 100%;
    background: #f1962e;
    padding-top: 15px;
    padding-bottom: 15px;
}

.queue-times-table-item-queue-time-icon {
    font-size: 20px;
}

body {
    font-family: 'FlamaMedium';
    background-color: black;
}


@media (prefers-color-scheme: dark) {
    body {
        background-color: black;
    }
  }









  .park-info-container {
    color: white;
}

.park-info-title {
    font-size: 23px;
}

.park-info-container {
    display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 24px;
        background: #021a23;
        margin: 10px;
        padding: padding-top;
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 11px;
}

.park-info-status {
    display: flex;
    margin-top: 5px
}


.park-info-special-event-name {
    font-size: 14px;
}

.park-info-hours {
    font-size: 16px;
}

.stats-bar {
    height: 150px;
    background: blue;
}
.guage-container {
    height: inherit;
    width: 50%
}

.stats-bar-inner {
    height: inherit;
}

.radial-gauge {
    width: 90%;
    height: 90%;
    max-width: 200px;
    max-height: 200px;
}

.park-info-right {
    width: 48%;
    max-width: 50%;
    max-height: 200px;
    display: flex
;
    flex-direction: column;
    align-items: center;
    align-content: center;
}


.park-info-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-left: 20px;
    margin-top: 20px;
}

.park-info-special-hours {
    text-align: left;
}


.desktop-queue-tables {
    display: flex;
    justify-items: stretch;
    align-items: flex-start;
    align-content: center;
    flex-direction: row;
    justify-content: center;
    
}

.queue-times-table.desktop-queue-table {
    width: 30%;
}

.queue-times-table-desktop-group-name {
    background: #28373f;
    display: flex;
    padding-left: 10px;
    height: 40px;
    align-items: center;
    font-size: 18px;
    color: white;
    margin-bottom: 1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}



.theme-park-wiki-footer {
    color: white;
    margin-bottom: 50px;
}

.ride-times-footer {
    color: white;
    margin-bottom: 50px;
}
.headline-container {
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}


.headline-text {
    font-size: 20px;
}


.headline-container {
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headline-text {
    font-size: 20px;
}

.headline-container {
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headline-text {}

.headline-text {
    font-size: 20px;
}

.headline-container {
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headline-text {}

.headline-text {
    font-size: 20px;
}

.parks-list-title {
    font-size: 27px;
}

.parks-list-container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-left: 20px;
    margin-right: 20px;
}

.parks-list-subtitle {
    font-size: 19px;
}

.parks-list-list-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.parks-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    height: 50px;
    background-color: gray;
}

.parks-list-item-inner {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: inherit;
}

.parks-list-item-warning {
    width: 10%;
    color: orange;
    font-size: 20px;
    max-width: 50px;
}
.parks-list-item-title {
    flex-grow: 1;
    text-align: left;
}

.parks-list-item-title-nowarning {
    flex-grow: 1;
    text-align: left;
    margin-left: 10px;
}


.parks-list-item-status {
    width: 30%;
    color:  white;
    text-align: right;
    padding-right: 10px;
}

.parks-list-item-end {
    padding-right: 6px;
    /* text-align: right; */
    font-size: 26px;
}

.parks-list-item-warning-dropdown {
    height: auto;
    display: flex
;
    background: #cd5700;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
}

a {
    text-decoration: none;
    color: black;
}




.parks-list-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    background: #4e5d66;
    color: white;
}

.parks-list-item:nth-child(even) {
    background: #28373f

}

.parks-list-item:first-child {
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;

}

.parks-list-item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
 
 }


 .closed {
    color: #ff4e4e;
}

.open-now {
    color: #00cb00;
}

.soon {
    color: orange;
}


.parks-list-title {
    color: white;
    margin-top: 30px;
    margin-bottom: 10px;
}


.cookie-warning {
    position: absolute;
    background: #021a23;
    bottom: 0;
    color: white;
    width: 100dvw;
}


p.cookie-warning-inner {
    padding: 10px;
}

button.cookie-dismiss-button {
    appearance: none;
    background: red;
    padding: 10px;
    width: 150px;
    border-radius: 20px;
    border: none;
    margin-bottom: 20px;
    color: white;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.desktop-header {
    height: 50px;
    background: #021a23;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    
}

.desktop-header-inner {
    width: 100%;
    display: flex;
    margin-left: 10px;
}

.desktop-header-menu {
    /* flex-grow: 1; */
    width: 40%;
 
}

.deksktop-header-menu-item {
    width: auto;
    color: white;
    padding-right: 15px;
    padding-left: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;

}

.desktop-header-menu-container {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.menu-seperator {
    border-right: 1px solid white;
    border-radius: 0;
margin-right: 10px;
    margin-left: 10px;
}

.deksktop-header-menu-item:hover {

background-color: #075470;
transition: all 0.3s;

}


.desktop-header-ride-times {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-right: 15px;
    justify-content: flex-end;
   
}

.desktop-header-ride-times-inner {
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
}


.desktop-header-ride-times-inner:hover{


    background-color: orange;
    transition:  all 0.3s;
    


}
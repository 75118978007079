@font-face {
    font-family: 'FlamaMedium';
    src: url("../fonts/FlamaMedium.ttf");
}

.mobile-header {
    
        height: 50px;
        background: #021a23;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
    }
    
    
    .mobile-header-title {
        font-size: 25px;
        color: white;
        font-family: 'FlamaMedium';
    }

.loading-center {

    width: 100dvw;
    height: 100dvh;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-size: 40px;





}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 



  .page-loader{
      position: absolute;
      background: #040404ee;
      height: 100dvh;
      top: 0;
      left: 0;
      width: 100dvw;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .loading-pop-up-container {
      width: 150px;
      height: 150px;
      padding: 20px;
      background: #021a23;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .loadiing-pop-up-text {
      margin-top: 20px;
      color: white;
      font-size: 18px;
  }




.list {
    background: #2f454f;
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
    border-radius: 20px;
}


.list-item-title {
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    margin-left: 10px;
    color: white;
}
.list-item-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}



.list-item-item {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background: #556974;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    
}

.list-item-item:last-child{
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
}

.list-item-item-end {

    font-size: 17px;
}


.short {
	color: #79a82f;
}
.medium {
	color: #c77d2e;
}

.long {
	color: #FF4C4C;
}


.queue-short {
	color: #79a82f;
}
.queue-medium {
	color: #c77d2e;
}

.queue-long {
	color: #FF4C4C;
}

.ride-details-header {
    display: flex
;
    align-items: center;
    justify-content: space-around;
    height: 80px;
}

.ride-details-header-title {
    font-size: 25px;
    width: 70%;
    text-overflow: ellipsis;
    overflow: none;
    color: white
}

.ride-details-header-status {
    width: 200px;
    font-size: 20px;
   
    padding: 6px;
    border-radius: 27px;
}

.ride-details-header-queue-minutes {
    color: white !important;
}


.calendar-date-picker {
    top: 0;
    left: 0;
    position: absolute;
    background: black;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
}

.ride-details-downtime-history {
color: white;
margin-left: 30px;
margin-right: 30px;
border-radius: 20px;
background-color: #556974;
margin-bottom: 25px;
}


.ride-details-downtime-history-title {
    font-size: 20px;
    background-color: #2f454f;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    
}




table.ride-details-downtime-history-table {
    width: 100%;
}


tr.ride-details-downtime-table-item {
    border-bottom: 1px solid black;
}

.ride-details-history-button {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100px;
    height: 50px;
    background-color: #298477;
    appearance: none;
    border: none;
    font-size: 14px;
    border-radius: 10px;
    color: white;
    font-family: 'FlamaMedium';


}

.ride-details-history-controls-container {

    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}